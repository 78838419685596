import api from "./api-service";
import { stringify } from "querystring";

const url = "chromosomes";

export const getAll = (filter) => api().get(`${url}?${stringify(filter)}`);

export const getOne = (id) => api().get(`${url}/${id}`);

export const create = (chromosome) => api().post(url, chromosome);

export const update = (chromosome) => api().put(url, chromosome);

export const changeOrder = ({ chromosome, direction }) => {
  return api().put(`${url}/order/direction/${direction}`, chromosome);
};
