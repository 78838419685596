<template>
  <v-container>
    <perseu-card title="Cromossomos">
      <v-btn color="secondary" slot="title-right" @click="create">
        Novo Cromossomo
      </v-btn>
      <v-flex slot="content">
        <filters-panel class="mb-3">
          <v-row>
            <v-col cols="12" sm="2">
              <v-switch v-model="query.active" label="Ativos"></v-switch>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-btn color="secondary" @click="list"> Buscar </v-btn>
          </v-row>
        </filters-panel>
        <v-data-table
          :items="chromosomes"
          :headers="headers"
          class="elevation-1"
        >
          <template v-slot:item.order="{ item }">
            <number-arrows
              :number="item.order"
              @change="(direction) => changeOrder({ item, direction })"
            />
          </template>
          <template v-slot:item.actions="props">
            <v-btn small icon @click="edit(props.item)" color="secondary">
              <v-icon small>edit</v-icon>
            </v-btn>
            <v-btn
              small
              icon
              v-if="props.item.active"
              @click="activeOrDeactivate(props.item, false)"
              color="secondary"
            >
              <v-icon small>delete</v-icon>
            </v-btn>
            <v-btn
              small
              v-else
              icon
              @click="activeOrDeactivate(props.item, true)"
              color="secondary"
            >
              <v-icon small>check</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-flex>
    </perseu-card>
    <form-chromosome ref="form" @close="closedForm" />
  </v-container>
</template>

<script>
import {
  getAll,
  update,
  create,
  getOne,
  changeOrder,
} from "@/services/chromosomes-service";

export default {
  components: {
    "form-chromosome": () => import("@/components/Chromosomes/Form"),
  },
  data: () => ({
    headers: [
      { text: "Núm.", value: "order", width: 200 },
      { text: "Descrição", value: "description" },
      {
        text: "Ações",
        value: "actions",
        sortable: false,
        align: "start",
        width: 200,
      },
    ],
    chromosomes: [],
    chromosome: {},
    query: {
      active: true,
    },
  }),
  created() {
    this.list();
  },
  methods: {
    async list() {
      try {
        this.$store.dispatch("loading/openDialog");
        let response = await getAll(this.query);
        this.chromosomes = response.data;
      } catch (e) {
        this.$errorHandler(e);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
    create() {
      this.$refs.form.create();
    },
    async edit({ id }) {
      try {
        this.$store.dispatch("loading/openDialog");
        const { data } = await getOne(id);
        this.$refs.form.edit(data);
      } catch (e) {
        this.$errorHandler(e);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
    async activeOrDeactivate(item, target) {
      try {
        this.$store.dispatch("loading/openDialog");
        item.active = target;
        await update(item);
        this.removeFromList(item);
      } catch (error) {
        this.$errorHandler(error);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
    removeFromList(chromosome) {
      const index = this.findIndexItem(chromosome);
      this.chromosomes.splice(index, 1);
    },
    findIndexItem(chromosomeParam) {
      return this.chromosomes.findIndex(
        (chromosome) => chromosome.id === chromosomeParam.id
      );
    },
    async closedForm(chromosome) {
      try {
        this.$store.dispatch("loading/openDialog");
        if (chromosome.id) {
          await update(chromosome);
          const index = this.findIndexItem(chromosome);
          this.chromosomes.splice(index, 1, chromosome);
        } else {
          const { data } = await create(chromosome);
          this.chromosomes.push(data);
        }
        this.$toasted.global.defaultSuccess();
      } catch (error) {
        this.$errorHandler(error);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
    async changeOrder({ item, direction }) {
      try {
        await changeOrder({ chromosome: item, direction });
        await this.list();
      } catch (error) {
        this.$errorHandler(error);
      }
    },
  },
};
</script>

<style></style>
